@import "../variables";

.o-table {
  .o-row {
    display: flex;
    font-weight: 600;
    font-size: 16px;
    .o-cell {
      flex: 1;
      text-align: center;
      align-content: center;
      flex-wrap: wrap;
    }
  }

  .o-header {
    font-size: 20px !important;
    line-height: 27px;
    color: $gray-text;
  }
  .content {
    overflow-y: auto;
  }
}

.pagination-controls {
  @include flex-content-end;
  margin-top: 15px;
  .ngx-pagination {
    @include flex-content-align-center;
  }

  .ngx-pagination li {
    height: 36px;
    width: 36px;
    align-content: center;
    text-align: center;
  }

  .ngx-pagination .current {
    background-color: $primary-color;
    border-radius: 6px;
  }

  .ngx-pagination .pagination-previous a::before,
  .ngx-pagination .pagination-previous.disabled::before {
    content: "<" !important;
  }

  .ngx-pagination .pagination-next a::after,
  .ngx-pagination .pagination-next.disabled::after {
    content: ">" !important;
  }

  .ngx-pagination a,
  .ngx-pagination button {
    color: $gray-text;
    text-decoration: none;
  }

  .ngx-pagination a:hover,
  .ngx-pagination button:hover {
    height: 36px;
    width: 36px;
    border-radius: 6px;
    align-content: center;
  }
}

.show-pagination {
  height: calc(100vh - 370px);
}

.hide-pagination {
  height: calc(100vh - 340px);
}

@media (max-width: 767px) {
  .ngx-pagination li {
    width: 50px !important;
  }
}
