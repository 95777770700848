.lg-sidebar {
  width: 1000px !important;
}

.md-sidebar {
  width: 650px !important;
}

.sidebar-w-750 {
  width: 750px !important;
}

.sidebar-w-850 {
  width: 850px !important;
}

.xl-sidebar {
  width: 1200px !important;
}

@media (max-width: 1000px) {
  .lg-sidebar {
    width: 100% !important;
  }
}

@media (max-width: 650px) {
  .md-sidebar {
    width: 100% !important;
  }
}

@media (max-width: 750px) {
  .sidebar-w-750 {
    width: 100% !important;
  }
}

@media (max-width: 850px) {
  .sidebar-w-850 {
    width: 100% !important;
  }
}

@media (max-width: 1200px) {
  .xl-sidebar {
    width: 100% !important;
  }
}

.o-sidebar-wrapper {
  .o-sidebar-header {
    padding: 14px 30px;
    @include flex-content-space-between;

    .title {
      font-size: 22px;
      font-weight: 700;
    }

    .action-btn-wrapper {
      .back-btn {
        margin-right: 5px;
      }
    }
  }
  .o-sidebar-body {
    padding: 20px 30px 0px 30px;
  }
}

.mat-drawer-container {
  position: unset !important;
  background-color: $header-schedule-bg-color !important;
}
