@import "src/assets/scss/variables";

.required {
  &:after {
    content: " *";
    color: $color-danger;
    font-weight: 600;
  }
}

.row {
  --bs-gutter-y: 1rem !important;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  height: 100%;
  width: 100%;
  opacity: 0;
  position: absolute;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  background-size: cover;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: white;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}

.position-relative {
  app-error-messages {
    left: 13px;
    bottom: -17px;
  }
}

*[class^="col-"] {
  position: relative;
}

.fw-bold {
  font-weight: 500 !important;
}

.text-reset {
  opacity: 0.6;
}

.mx-w-300 {
  max-width: 300px;
}

.mat-mdc-form-field {
  width: 100%;
  margin-top: 0 !important;
}

.mdc-text-field {
  background-color: $gray-bg-light !important;
  border-radius: 6px !important;
  transition:
    background-color 0.15s,
    border-color 0.15s,
    box-shadow 0.15s;
  width: 100%;
}

mat-label {
  color: $gray-text !important;
  font-size: 14px;
  font-weight: 600;
}

.mdc-text-field__input {
  font-weight: 500 !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: $gray-bg-light !important;
}

input:-webkit-autofill {
  background: $gray-bg-light;
  -webkit-text-fill-color: $black-color;
  -webkit-background-clip: text;
  box-shadow: 0 0 0 50px $gray-bg-light inset;
}

.mdc-line-ripple {
  display: none;
}

.mat-mdc-form-field-required-marker {
  color: $color-danger;
}

.material-icons {
  font-family: "Material Icons" !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  border-color: #e1e3ea !important;
  background-color: $white-color !important;
  .mdc-checkbox__checkmark {
    color: $gray-text;
  }
}

.mdc-checkbox__ripple {
  background-color: $white-color !important;
}

.mdc-checkbox__background {
  border-color: #e1e3ea !important;
}

.mdc-checkbox {
  padding-right: 5px !important;
}

.mdc-label {
  color: $gray-text !important;
  font-weight: 600;
}

.mat-mdc-checkbox-touch-target {
  height: 20px !important;
  left: 58% !important;
  width: 20px !important;
}

.mat-icon {
  font-size: 20px !important;
  color: $gray-text !important;
}

.form-label {
  color: $gray-text !important;
  margin-bottom: 10px;
  font-weight: 600;
}

.btn-typed-options-wrapper {
  display: flex;
  flex-wrap: wrap;

  .btn-typed-option {
    margin-right: 10px;
    padding: 8px 14px;
    border-radius: 6px;
    cursor: pointer;
    border: 1px solid $btn-options-border-color;
    font-size: 14px;
    font-weight: 600;
  }

  .active {
    color: $primary-color;
    border-color: $primary-color;
  }
}

.mat-calendar-body-selected {
  background-color: $primary-color !important;
}

.mat-calendar-body-selected.mat-calendar-body-today {
  box-shadow: none !important;
}

.search-bar-wrapper {
  .mat-mdc-form-field-flex {
    align-items: center !important;
    .mat-mdc-form-field-text-prefix {
      @include flex-content-align-center;
      padding-top: 5px;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-flex,
  .mat-mdc-form-field-focus-overlay,
  .mat-mdc-text-field-wrapper {
    height: 40px !important;
  }

  .mat-mdc-form-field-infix {
    min-height: 38px !important;
    padding: 7px 0 !important;
  }
}

.filter-and-count-wrapper {
  display: flex;
  justify-content: space-between;

  .filter-wrapper {
    display: flex;
    height: 40px;

    .search-bar-wrapper {
      margin-left: 10px;
    }
  }
}

.search-and-count-wrapper-auth {
  @include flex-content-align-center;
  margin-bottom: 20px;
  .total-users {
    font-size: 18px;
    color: $dark-gray-text;
    span {
      padding-right: 20px;
      color: $primary-color;
      border-right: 1px solid $btn-options-border-color;
      margin-right: 10px;
    }
  }
}

.single-btn-select-wrapper {
  height: 40px;
  display: flex;
  .select-btn {
    @include flex-content-align-center;
    padding: 12px 10px;
    border: 1px solid $btn-options-border-color;
    color: $original-black-color;
    cursor: pointer;
  }

  .select-btn:first-child {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .select-btn:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .active {
    color: $primary-color;
    border-color: $primary-color;
  }
}

.toggle-btn-wrapper {
  display: flex;
  background-color: $secondary-color;
  border-radius: 5px;
  height: 30px;
  font-size: 12px;
  font-weight: 700;

  .select-btn {
    @include flex-content-align-center;
    padding: 4px 15px;
    color: $original-black-color;
    cursor: pointer;
  }

  .active {
    color: $white-color;
    background-color: $primary-color;
    margin: 4px;
    border-radius: 5px;
  }
}

.ngx-timepicker {
  background-color: $gray-bg-light;
  padding: 2px;
  height: 35px !important;
  border-radius: 6px;
  border-bottom: unset !important;
}

.ngx-timepicker-control__input {
  background-color: $gray-bg-light;
  font-size: 14px !important;
}

.ngx-timepicker-control__arrow {
  padding-top: 3px;
  font-size: 7px !important;
}

.period-control__button {
  font-size: 14px !important;
}

.period-control__button::after,
.ngx-timepicker-control--active::after {
  background-color: $primary-color !important;
}

.period-control__arrow {
  font-size: 9px !important;
}

.period-selector {
  background-color: $white-color !important;
  right: calc(-50% + -30px) !important;
}

.ngx-material-timepicker-toggle {
  pointer-events: none;
  opacity: 0.5;
  padding: 8px !important;

  svg {
    height: 22px !important;
    width: 20px !important;
  }
}

.period-selector__button--active {
  color: $primary-color !important;
}

@media (max-width: 800px) {
  .btn-typed-option {
    margin-bottom: 8px;
  }

  .filter-and-count-wrapper {
    flex-wrap: wrap;
    .search-and-count-wrapper-auth {
      width: 100%;

      .search-bar-wrapper {
        width: 100%;
      }
    }

    .filter-wrapper {
      flex-wrap: wrap;
      height: unset;
      width: 100%;

      mat-form-field {
        margin-bottom: 10px;
        margin-left: 0 !important;
      }
    }
  }
}

@media (max-width: 1160px) and (min-width: 800px) {
  .filter-and-count-wrapper {
    flex-wrap: wrap;

    .filter-wrapper {
      margin-bottom: 10px;
    }
  }
}
