$card-box-shadow: 2px 2px 24px 0 rgba(68, 68, 68, 0.16);
$dropdown-box-shadow: 0 2px 10px 0 rgb(0 0 0 / 25%);

$black-color: #222222;
$original-black-color: #000000;
$active-color: #455fd7;
$primary-color: #42a694;
$secondary-color: #efefef;
$placeholder-color: #989898;

$gray-text: #7e8299;
$gray-text-light: #a1a6b7;
$gray-bg-light: #f9f9f9;
$dark-gray-text: #434349;
$black-shade-text: #1e1e1e;
$gray-bg-dark: #f6f6f6;

$table-header-active-col-color: #e6f1ff;
$table-header-color: #f3f5f8;
$table-row-color: #fafbfe;

$theme-light-color: #ffece3;
$form-border-color: #c8d1ff;
$form-placeholder-color: #747c85;
$disable-color: #797979;
$disable-background-color: #e8e8e8;

$modal-header-color: #e0edff;
$label-highlight-color: #2e86ff;
$blue-light-color: #ffffff;
$border-color: #e3e3e3;

$header-shadow: 0px 5px 5px 0px rgb(170 170 170 / 20%);
$sidebar-bg: #242426;

$white-color: #ffffff;
$un-auth-title-color: #181c32;
$invalid-input-color: #f44336;
$color-danger: #c42b31;
$color-success: #4caf50;
$color-warning: #ff9800;
$color-info: #2196f3;

$btn-options-border-color: #d5d5d5;
$header-schedule-bg-color: #eef6ef;
$close-btn-bg: #fc5555;
$dot-color: #d9d9d9;
$dot-dark-gray-color: #777777;
$red-btn: #f1416c;
$red-bg-color: #fff5f8;
$resource-cell-color: #f5f5f5;
$resolved-status: #66bbff;

$yellow-color: #e9922b;
$light-yellow-color: #fff5ea;

$primary-color-filter: brightness(0) saturate(100%) invert(61%) sepia(15%) saturate(1371%) hue-rotate(119deg)
  brightness(90%) contrast(87%);
$gray-filter: brightness(0) saturate(100%) invert(51%) sepia(22%) saturate(289%) hue-rotate(194deg) brightness(97%)
  contrast(93%);
$yellow-filter: brightness(0) saturate(100%) invert(65%) sepia(87%) saturate(897%) hue-rotate(338deg) brightness(94%)
  contrast(94%);
$black-filter: invert(100%) brightness(0%) contrast(100%);
$white-filter: brightness(0) invert(1);
$red-filter: brightness(0) saturate(100%) invert(33%) sepia(86%) saturate(2366%) hue-rotate(324deg) brightness(101%)
  contrast(89%);
