@import "src/assets/scss/theme/mixins";
@import "src/assets/scss/variables";

body {
  background-color: var(--surface-ground) !important;
  color: var(--text-color) !important;
  overflow: hidden !important;
}

.login-input-group .password-eye {
  top: 25px !important;
}

label {
  color: var(--text-color) !important;
  font-size: 14px;
  letter-spacing: -0.28px;
  line-height: 21px;
}

a.active {
  color: #fff !important;
  text-decoration: none;
  background-color: $active-color !important;
}

fa-icon .fa-sort {
  font-size: 13px;
  color: $black-color;
}

fa-icon .fa-sort-up,
fa-icon .fa-sort-down {
  font-size: 13px;
  color: $active-color;
}

fa-icon .fa-sort-down {
  margin-bottom: 3px;
}

fa-icon .fa-sort-up {
  margin-bottom: -3px;
}

fa-icon:hover {
  cursor: pointer;
}

:root {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #e7e7e7;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: #e7e7e7;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgb(158, 158, 158);
  }

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-state-layer-size: 32px;
    --mdc-radio-disabled-selected-icon-color: rgba(0, 0, 0, 0);
    --mdc-radio-disabled-unselected-icon-color: rgba(0, 0, 0, 0);
    --mdc-radio-unselected-hover-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-radio-selected-focus-icon-color: #42a694;
    --mdc-radio-selected-hover-icon-color: #42a694;
    --mdc-radio-selected-icon-color: #42a694;
    --mdc-radio-selected-pressed-icon-color: #42a694;
    --mat-radio-ripple-color: rgba(0, 0, 0, 0);
    --mat-radio-checked-ripple-color: rgba(0, 0, 0, 0);
    --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  }

  .mdc-checkbox {
    --mdc-checkbox-state-layer-size: 20px;
  }

  .mat-datepicker-toggle-active {
    --mat-datepicker-toggle-active-state-icon-color: #42a694;
  }
}

.p-sidebar-content {
  padding: 0 !important;
  overflow-y: hidden;

  .content {
    padding: 20px;
    // position: relative;
    max-height: calc(100vh - 120px);
    // overflow-y: auto;
    height: 100%;
  }
}

.p-sidebar-content {
  position: relative;
}

.modal-title {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;

  * {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 27px;
    margin-bottom: 0;
  }

  fa-icon {
    padding: 0px 8px;
    height: 1.8rem;
  }
}

.modal-footer {
  position: fixed;
  bottom: 0;
  padding: 0.3rem;
  width: -webkit-fill-available;
  flex-wrap: nowrap !important;
  z-index: 2000;
  min-height: 55px;
}

.p-sidebar-content {
  padding: 0 !important;
  overflow-y: hidden;

  .content {
    height: 100%;
    overflow-x: auto;
  }
}

.p-sidebar-content {
  position: relative;
}

.text-danger {
  color: red;
}

.text-gray {
  color: $gray-text;
}

.primary-color {
  color: $primary-color;
}

.text-black {
  color: $original-black-color;
}

.text-red {
  color: $red-btn;
}

.text-yellow {
  color: $yellow-color;
}

.text-sm {
  font-size: 14px;
}

.icon {
  font-size: 1rem;
}

.left {
  position: relative;

  img {
    position: absolute;
    left: 10%;
    top: 24%;
  }

  span {
    margin-left: 10px;
  }
}

.scrolltop-position {
  bottom: 38px !important;
}

.pointer {
  cursor: pointer;
}

.placeholder-name {
  background-color: $header-schedule-bg-color;
  border-radius: 6px;
  margin-right: 10px;
  div {
    @include flex-content-center;
    height: inherit;
  }
}

.hidden {
  visibility: hidden;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.p-sidebar {
  .p-sidebar-header {
    padding: 0;
  }

  .p-sidebar-content {
    height: 100vh;
  }
}

.hover-item {
  &:hover {
    color: var(--primary-color);
  }
}

@media (max-width: 600px) {
  .btn-add {
    padding: 0 15px !important;

    img {
      left: 12% !important;
    }
  }

  .hide-in-mobile {
    display: none;
  }
}

.mt-20 {
  margin-top: 20px !important;
}

.success-snackbar-color {
  .mdc-snackbar__surface {
    background: $color-success !important;
  }
}

.warning-snackbar-color {
  .mdc-snackbar__surface {
    background-color: $color-warning !important;
  }
}

.error-snackbar-color {
  .mdc-snackbar__surface {
    background-color: $color-danger !important;
  }
}

.info-snackbar-color {
  .mdc-snackbar__surface {
    background-color: $color-info !important;
  }
}

.snackbar {
  .mdc-button__label {
    color: $white-color;
  }

  .mat-mdc-snack-bar-action {
    min-width: auto;
  }
}

.dotted-divider {
  border-top: 1px dashed $btn-options-border-color;
  width: 100%;
  margin: 15px 0;
}

.dot {
  border-radius: 50%;
  background-color: $dot-color;
  height: 5px;
  width: 5px;
  margin: 0px 10px;
}

.dot:first-child {
  display: none;
}

.divider {
  flex-grow: 1;
  height: 1px;
  background-color: #d9d9d9;
}

.strike {
  text-decoration: line-through;
}

.header-tab-only-btn {
  background: $white-color;
  @include flex-content-end;
  padding: 5px 10px 0 10px;

  .action-btn {
    margin-bottom: 5px;
    margin-right: 10px;
  }
}

.header-tab-with-btn {
  background: $white-color;
  @include flex-content-space-between;
  padding: 5px 10px 0 10px;
  min-height: 50px;

  .title {
    font-weight: 700;
    font-size: 16px;
  }

  .tab-item-content {
    min-height: 40px;
    @include flex-content-center;

    .item {
      margin-right: 20px;
      color: $gray-text;
      font-size: 16px;
      height: inherit;
      padding: 9px;
      cursor: pointer;
      font-weight: 600;
    }

    .active-item {
      border-bottom: 2px solid $primary-color;
      color: $black-color;
    }
  }

  .back-btn-wrapper {
    @include flex-content-align-center;
    color: $primary-color;
    cursor: pointer;

    mat-icon {
      color: $primary-color !important;
      height: auto;
    }
  }

  .action-btn-wrapper {
    .action-btn {
      margin-bottom: 5px;
      margin-right: 10px;
    }
  }
}

.no-data-found-wrapper {
  @include flex-content-center;
  height: 100%;
}

@media (max-width: 767px) {
  .no-data-found-card {
    .o-card {
      min-height: 540px;

      .o-card-body {
        height: 500px;
        align-items: center !important;
      }
    }
  }
}

.auth-page-with-header {
  padding: 20px;
  height: calc(100vh - 70px);
  overflow: auto;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.content-loader {
  @include flex-content-center;
  margin-top: 100px;

  .dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: $primary-color;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out both;
  }

  .dot:nth-child(1) {
    animation-delay: -0.32s;
  }

  .dot:nth-child(2) {
    animation-delay: -0.16s;
  }
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.mat-mdc-select-panel {
  scrollbar-width: none;
  scroll-behavior: smooth;
  border-radius: 6px;
}

.mat-mdc-option.mdc-list-item {
  font-family: "Nunito Sans";
  font-size: 14px;
}

.mat-mdc-option {
  min-height: 40px !important;
}

.mat-mdc-option .mat-pseudo-checkbox-minimal {
  display: none;
}

.mat-mdc-select-arrow svg {
  fill: $gray-text !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: $primary-color !important;
}

.mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: unset !important;
}

.mbsc-ios.mbsc-popup-body {
  background-color: $white-color !important;
}

.mat-checkbox-custom {
  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
    border-color: $primary-color !important;
    background-color: $white-color !important;
  }

  .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
    color: $primary-color !important;
  }

  .mdc-checkbox__checkmark {
    top: 3px !important;
    height: 10px;
  }

  .mdc-checkbox__background {
    border-radius: 6px;
    border-width: 1px !important;
    border-color: $btn-options-border-color !important;
  }

  .mdc-label {
    color: $black-shade-text !important;
    font-size: 16px !important;
  }

  .mat-mdc-checkbox .mdc-checkbox__ripple {
    display: none;
  }

  &.mat-mdc-checkbox-checked {
    .mdc-label {
      color: $primary-color !important;
    }
  }
}
