@import "../variables";

.mat-primary-btn {
  height: 40px !important;
  background-color: $primary-color !important;
  border-radius: 6px !important;

  .mat-mdc-focus-indicator {
    color: $white-color;
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    border-radius: 6px;
    height: 40px !important;
  }
}

.mat-red-btn {
  height: 40px !important;
  background-color: $red-btn !important;
  border-radius: 6px !important;

  .mat-mdc-focus-indicator {
    color: $white-color;
    background-color: $red-btn !important;
    border-color: $red-btn !important;
    border-radius: 6px;
    height: 40px !important;
  }
}

.mat-mdc-raised-button,
.mat-mdc-raised-button:hover {
  box-shadow: none !important;
}

.mdc-button__label {
  font-size: 14px;
}

.mat-primary-btn.mat-mdc-button-disabled {
  color: #fff !important;
  .mat-mdc-focus-indicator {
    background-color: lighten($primary-color, 10%) !important;
    border-color: lighten($primary-color, 7%) !important;
  }
}

.mat-accent-btn {
  height: 40px !important;
  color: $gray-text !important;
  border-color: $secondary-color !important;
  border-radius: 6px !important;
  background-color: $secondary-color !important;
}

.mdc-button {
  border-radius: 6px;
}

.mat-mdc-raised-button:disabled {
  color: #ffffffb0 !important;
}

.mat-warn-btn {
  height: 40px !important;
  border-radius: 6px;
}
