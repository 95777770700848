@import "src/assets/scss/variables";

@mixin flex-content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-content-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-content-align-center {
  display: flex;
  align-items: center;
}

@mixin flex-content-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-content-align-start {
  display: flex;
  align-items: flex-start;
}

@mixin w-h-100 {
  width: 100% !important;
  height: 100% !important;
}

@mixin box-shadow {
  box-shadow: $card-box-shadow;
}

@mixin ellipse($width) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @if $width {
    width: $width;
  }
}
