/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import "bootstrap/dist/css/bootstrap.css";
@import "assets/scss/variables";
@import "assets/scss/theme/mixins";
@import "assets/scss/theme/font";
@import "assets/scss/theme/theme";
@import "assets/scss/theme/button";
@import "assets/scss/theme/table";
@import "assets/scss/theme/card";
@import "assets/scss/custom";
@import "assets/scss/theme/sidebars";
@import "assets/scss/theme/form";

@import "@mobiscroll/angular/dist/css/mobiscroll.scss";
