@import "src/assets/scss/variables";

.o-card {
  background: $white-color;
  border-radius: 12px;
  padding: 30px;

  .o-card-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .dotted-divider {
    border-top: 1px dashed $btn-options-border-color;
    width: 100%;
    margin: 15px 0;
  }
}

.no-data-found-card {
  @include flex-content-center;
  padding: 20px;
  background-color: $white-color;
  border-radius: 12px;
  height: 487px;
}
